import {graphql} from "gatsby";
import React from "react";

import Layout from "../../components/Layout";
import Seo from "../../components/SEO";
import BlogGallery from "../../createdpages/blog/BlogGallery/BlogGallery";

const Presspage = ({data}) => {
  return (
    <>
      <Seo
        title='Press'
        description='If you are interested to know about our achievements, you are at the right place. Check all the press releases here!'
      />
      <Layout>
        <div className='full-bleed layouted bg-gray-200'>
          <div className='coll-header my-24'>
            <h1 style={{color: "#02152D"}}>In the News</h1>
            <br />
            <h3 className='light w-full w-2/4'>
              Catchup on the latest media updates & press coverage. For media
              enquiries reach out to us at <a href='mailto:media@getmason.io'>media@getmason.io</a>
            </h3>
          </div>
          <BlogGallery data={data.sanityPresspage.posts} post_type='link' />
        </div>
      </Layout>
    </>
  );
};

export const query = graphql`
  {
    sanityPresspage {
      posts {
        author_dp {
          asset {
            gatsbyImageData
          }
        }
        authorAlt
        author
        post_tag
        title
        url
        img {
          asset {
            gatsbyImageData
          }
        }
        alt
      }
    }
  }
`;

export default Presspage;
